<template>
  <b-overlay :show="loading2" class="row">
    <div class="col-12 col-lg-10 c g">
      <div class="card card-body">
        <div class="row">
          <div class="col-12 col-lg-6 c">
            <div class="form-group">
              <h5 for="">الإسم/الهاتف</h5>
              <input type="text" ref="filter_text" class="form-control" />
            </div>
          </div>
          <div class="col-12 text-center">
            <button
              class="btn btn-relief-success"
              ref="searchbtn"
              @click="
                page = 0;
                get();
              "
            >
              <i class="fa fa-search"></i> بحث
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4>
            <i class="fa fa-list"></i>
            استعراض اولياء الامور
          </h4>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered custom-table">
              <thead>
                <th>الإسم</th>
                <th>الهاتف</th>
                <th>تاريخ الإضافة</th>
                <th>ملاحظات</th>
                <th>خيارات</th>
              </thead>
              <tbody>
                <tr v-for="parent in parents" :key="parent._id">
                  <td>{{ parent.name }}</td>
                  <td>{{ parent.phone }}</td>
                  <td>{{ parent.date.split("T")[0] }}</td>
                  <td>{{ parent.notes }}</td>
                  <td>
                    <b-dropdown text="خيارات" variant="relief-primary">
                      <b-dropdown-item
                        @click="$router.push('/parents/overview/' + parent._id)"
                      >
                        <i class="fa fa-user text-success"></i>
                        نظرة عامة
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="$router.push('/parents/edit/' + parent._id)"
                      >
                        <i class="fa fa-user-edit text-primary"></i>
                        تعديل
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteParent(parent._id)">
                        <i class="fa fa-user-times text-danger"></i>
                        حذف
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            class="btn btn-secondary"
            v-if="page > 0"
            @click="
              page > 0 ? (page = page - 1) : (page = page);
              get();
            "
          >
            <i class="fa fa-arrow-right"></i> السابق
          </button>
          <button class="btn btn-default">
            {{ page + 1 }}
          </button>
          <button
            class="btn btn-primary"
            v-if="parents.length > 0"
            @click="
              page = page + 1;
              get();
            "
          >
            التالي <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BLink, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BOverlay,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      loading: true,
      parents: [],
      filter: {},
      page: 0,
      f: false,
      loading2: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    if (checkPer(this, "parents", "view")) {
      var g = this;
      g.get();
    }
  },
  methods: {
    deleteParent(id) {
      var g = this;
      if (
        confirm("متأكد من حذف ولي الأمر؟") &&
        checkPer(this, "parents", "delete")
      ) {
        $.post(api + "/admin/parents/delete", {
          jwt: g.user.jwt,
          id: id,
        }).then(function (r) {
          alert("تم الحذف");
          g.get();
        });
      }
    },
    get() {
      var g = this;
      g.loading = true;
      setTimeout(() => {
        var t = g.$refs.searchbtn.innerHTML;
        g.$refs.searchbtn.innerHTML = `<div class="spinner-border text-white" role="status"></div>`;
        g.filter = {
          filter_text: g.$refs.filter_text.value,
        };
        $.post(api + "/admin/parents/list", {
          jwt: g.user.jwt,
          filter: JSON.stringify(g.filter),
          page: g.page,
        }).then(function (r) {
          localStorage.setItem(
            "parents",
            JSON.stringify(JSON.parse(r).response)
          );
          g.parents = JSON.parse(r).response;
          g.loading2 = false;
          g.loading = false;
          g.$refs.searchbtn.innerHTML = t;
        });
      }, 1);
    },
  },
};
</script>